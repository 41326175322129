import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


function Contact() {
  return (
    <div className="p-10 bg-slate-100"> 
      <div className="text-center">
        <h1 className="text-4xl font-bold text-cyan-600">Get in Touch with Us</h1>
        <p className="text-lg mt-2">Whether you have questions, need support, or want to schedule a demo, we're here to help.</p>
      </div>

      <div className="flex justify-center flex-col lg:flex-row p-10">
        <div className="lg:w-1/4 p-4 bg-white rounded-lg shadow-md ml-2 mt-4">
          <h2 className="text-3xl text-cyan-600 text-center mb-4">Contact Information</h2>
          <div className="p-5">
            <p className="text-lg mb-2">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              Phone Number
              <p>WhatsApp / Telegram</p>
            </p>
            <p className="text-lg mb-2"> +1 (123) 456-7890</p>
          </div>
          <div className="p-5 items-center">
            <p className="text-lg mb-2 flex items-center">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              Email Address
            </p>
            <p className="text-lg">contact@example.com</p>
          </div>
        </div>

        <div className="lg:w-1/2 p-4 bg-white rounded-lg shadow-md ml-2 mt-4">
          <p className="text-center italic">We’d Love to Hear From You!</p>
          <p className="text-center italic">Fill out the form below, and our team will get back to you as soon as possible.</p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg mb-2" htmlFor="name">Name</label>
              <input className="w-full p-2 border border-gray-300 rounded" type="text" id="name" name="name" required />
            </div>
            <div>
              <label className="block text-lg mb-2" htmlFor="email">Email</label>
              <input className="w-full p-2 border border-gray-300 rounded" type="email" id="email" name="email" required />
            </div>
            <div>
              <label className="block text-lg mb-2" htmlFor="message">Message</label>
              <textarea className="w-full p-2 border border-gray-300 rounded" id="message" name="message" rows="4" required></textarea>
            </div>
            <button className="w-full px-4 py-2 bg-cyan-600 text-white rounded" type="submit">Send</button>
          </form>
        </div>

      </div>
    </div>
  );
}

export default Contact;

// import React from 'react';

// function Contact() {
//   return (
//     <div className="text-center p-20">
//       <h1 className="text-4xl text-cyan-600">Contact Us</h1>
//       <p className="text-lg mt-2">You can reach us at contact@example.com.</p>
//     </div>
//   );
// }

// export default Contact;